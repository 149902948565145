import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { DangerouslySetInnerHtml, Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import BlockCarousel from 'components/BlockCarousel';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { SLIDER_SETTINGS } from './constants';
import { IProductsSliderBannerProps } from './model';

import './ProductsSliderBanner.scss';

const ProductsSliderBanner: FC<IProductsSliderBannerProps> = ({
  title,
  productCards,
  bannerBackground,
  bannerBackgroundMobile,
  additionalBottomSpace,
  description,
  isSliderWithDots,
}) => {
  const { isDesktop } = useScreenRecognition();
  const background =
    !isDesktop && bannerBackgroundMobile ? bannerBackgroundMobile : bannerBackground;
  const mobileProductList = [...productCards].sort(
    (a, b) => a.properties.mobileOrder - b.properties.mobileOrder
  );
  const productCardsList = !isDesktop ? mobileProductList : productCards;

  return (
    <div
      className={classnames('products-slider-banner', {
        [`products-slider-banner--style-${additionalBottomSpace}`]: additionalBottomSpace,
      })}
    >
      <Container fluid>
        <DangerouslySetInnerHtml html={title} className="products-slider-banner__title" />

        <div className="products-slider-banner__cards">
          <BlockCarousel
            settings={{
              ...SLIDER_SETTINGS,
              dots: isSliderWithDots,
            }}
          >
            {productCardsList.map(
              ({
                properties: {
                  productName,
                  productImage,
                  advantageIcons,
                  productLink,
                  cardBackground,
                  isPrimaryCardStyle,
                },
              }) => (
                <div
                  className={classnames('products-slider-banner__card', {
                    [`products-slider-banner__card--style-${cardBackground[0]}`]: cardBackground[0],
                    'products-slider-banner__card--primary-style': isPrimaryCardStyle,
                  })}
                  key={productName}
                >
                  <DangerouslySetInnerHtml
                    html={productName}
                    className="products-slider-banner__card-name"
                  />

                  <ul className="products-slider-banner__card-icons">
                    {advantageIcons.map(({ properties: { image, imageAlt, imageName } }) => (
                      <li className="products-slider-banner__card-icon" key={imageAlt}>
                        <GatsbyImage image={image} alt={imageAlt} objectFit="contain" />
                        <DangerouslySetInnerHtml
                          html={imageName}
                          className="products-slider-banner__image-name"
                        />
                      </li>
                    ))}
                  </ul>

                  <div className="products-slider-banner__card-image">
                    <GatsbyImage
                      image={productImage[0].properties.image}
                      alt={productImage[0].properties.imageAlt}
                      objectFit="contain"
                    />
                  </div>

                  <div className="products-slider-banner__card-link-wrapper">
                    <Button
                      variant="white"
                      classes="products-slider-banner__card-link"
                      to={productLink[0].properties.link[0].url}
                      ariaLabel={productLink[0].properties.ariaLabel}
                    >
                      {productLink[0].properties.link[0].name}
                    </Button>
                  </div>
                </div>
              )
            )}
          </BlockCarousel>
        </div>

        <DangerouslySetInnerHtml
          html={description}
          className="products-slider-banner__description"
        />
      </Container>

      <div className="products-slider-banner__background">
        <GatsbyImage image={background} objectPosition="50% 0" objectFit="contain" />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentProductsSliderBanner on TProductsSliderBanner {
    structure
    properties {
      title
      description
      bannerBackground {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      additionalBottomSpace
      bannerBackgroundMobile {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      isSliderWithDots
      productCards {
        properties {
          productName
          cardBackground
          isPrimaryCardStyle
          mobileOrder
          productImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 260) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
          advantageIcons {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
              imageName
            }
          }
          productLink {
            properties {
              ariaLabel
              link {
                name
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductsSliderBanner;
